@media (min-width: $screen-sm) {
  .financial-container {
    --financial-button-bar-height: 70px;
    --selector-search-box-height: 55px;
    --financial-body-height: calc(100vh - var(--body-height) - var(--component-header-height) - var(--financial-button-bar-height));
    --selector-body-height: calc(100vh - var(--body-height) - var(--component-header-height) - var(--financial-button-bar-height) - var(--selector-search-box-height));
  }
}

@media (max-width: $screen-sm) {
  .financial-container {
    --financial-button-bar-height: 75px;
    --selector-search-box-height: 55px;
    --financial-body-height: calc(100vh - var(--body-height) - var(--financial-button-bar-height));
    --selector-body-height: calc(100vh - var(--body-height) - var(--financial-button-bar-height) - var(--selector-search-box-height));
  }
}

.instrument-list {
  height: var(--financial-body-height);
  overflow-x: hidden;
  overflow-y: auto;

  .instrument-row {
    min-height: 45px;
    display: flex;
    align-items: center;

    @media (max-width: $screen-sm) {
      min-height: 40px;
    }
  }

  .instrument-list-empty {
    margin: 20px;

    h2 {
      font-size: 1em;
      font-weight: bold;
      padding: 20px 0 0 0;
      margin-top: 0;
    }

    p {
      font-size: 0.75em;
      line-height: 0.8;
      padding-bottom: 20px;
    }

    img {
      margin: 0 auto;
    }
  }
}

.instrument-selector {
  height: var(--selector-body-height);
  padding-top: 0px;
  overflow-x: hidden;
  overflow-y: auto;

  .instrument-row {
    min-height: 45px;
    display: flex;
    align-items: center;

    @media (max-width: $screen-sm) {
      min-height: 40px;
    }
  }
}

.instrument-selector-search-box {
  padding: 0px 0px 20px;
  margin: 0px 20px 0px;
  border-bottom: 1px solid #999999;

  svg {
    fill: #999999;
  }
}

.instrument-list-show {
  animation-name: slide-in-right;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.instrument-list-hide {
  animation-name: slide-out-right;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.instrument-list-cover {
  animation-name: slide-out;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.instrument-list-uncover {
  animation-name: slide-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.instrument-row {
  border-bottom: 1px solid #999999;
  margin-left: 20px;
  margin-right: 20px;
}

.instrument-name {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 20px;
}

.instrument-name-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.instrument-rate {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0px;
  color: #999999;
}

.instrument-delete {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  letter-spacing: 0px;
  line-height: 18px;

  & a {
    color: #d9534f;
    text-decoration: underline;
  }
}

.instrument-selector {
  & .instrument-row {
    cursor: pointer;
    padding-left: 10px;
  }
}

.instrument-selector-show {
  animation-name: slide-in-right;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.instrument-selector-hide {
  animation-name: slide-out-right;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.instrument-selector-popular {
  font-weight: bold;
  font-size: 0.8em;
  margin: 20px 0px 0px;
  padding: 0px 20px;
}

.instrument-selector-checkbox {
  position: absolute;
  margin-top: 0.2em;

  input[type=checkbox] { display:none; }

  input[type=checkbox] + label:before {
    font-family: FontAwesome;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    content: '\f096';
    cursor: pointer;
    font-size: 20px;
    color: #999999;
  }

  input[type=checkbox]:checked + label:before {
    color: #FFF;
    content: '\f14a';
  }
}

.instrument-selector-name {
  display: inline-block;
  margin-left: 30px;
}

.instrument-selector-no-results {
  font-size: 0.9em;
  margin: 20px;

  & > div {
    margin: 0 0 10px;
  }

  & > h2 {
    font-size: 1.2em;
    font-weight: 700;
    padding: 20px 0px;
    margin-top: 0px;
  }

  & > div:first-of-type {
    font-weight: bold;
  }

  & i {
    font-size: 15pt;
    color: $rise-green;
    margin-right: 5px;
  }

  & a {
    color: $dark-gray;

    &:hover > span {
      text-decoration: underline;
    }
  }

}

.instrument-row--is-selected {
  background: #107DDA;

  & .instrument-name {
    color: #FFF;
  }

  & .instrument-rate {
    color: #FFF;
  }
}

.financial-action-button-bar {
  height: var(--financial-button-bar-height);
  padding: 0px 20px;
  background: #FFF;

  & > div {
    background: #FFF;
    padding: 18px 0 0 0;
    border-top: 1px solid #999999;
  }
}
