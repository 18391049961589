.navbar-default .navbar-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.navbar {
  z-index: 1000;
  margin-bottom: 0;
  border: 0px;
  box-shadow: 0 3px 4px 0px rgba(0,0,0,.3);
  &.navbar-default {
    .navbar-nav>.open>a {
        background: #FFF;
    }
    .navbar-nav>li>a {
      border-radius: 4px;
      font-size: 13px;
      &:hover,&:focus {
        color: $rise-blue;
        background-color: $brand-bg;
      }
      &.selected {
        color: $rise-blue;
        background-color: $brand-bg;
      }
    }
    a.actions-nav {
        color: $text-color;
    }
  }
    .navbar-brand {
      padding: 6px;
      img {
        width: 100px;
      }
      .fa {
        padding: 10px;
      }
    }

    .navbar-nav {
      display: flex;
      align-items: center;
      margin: 0;

      &.actions-nav {
        margin-left: auto;
        display: flex;
      }
        >li {
            >a {
              padding: 6px;
              &.btn {
                  margin-top: 8px;
                  margin-bottom: 0;
              }
            }
            button {
              @extend .navbar-btn;
            }
            >a.btn-link,
            >button.btn-link {
                &:hover,
                &:focus,
                &:active {
                    color: gray;
                }
            }
        }
    }

}

.navbar-default {
    li.user-profile-dropdown {
      margin-left: auto;
        >a {
            padding: 8px;
            margin-top: 0;
        }
        text-align: right;
        .user-id {
            span {
              @extend .u_ellipsis-md;
              display: block;
              font-size: 13px;
              line-height: 18px;
            }
            .username {
                line-height: 16px;
            }
        }
        .profile-pic {
            margin-left: 8px;
            margin-top: 1px;
            border-radius: 4px;
            img {
                height: 100%;
            }
        }
    }
}

.navbar {
  /* Small Devices, Tablets */
  @media (max-width: $screen-xs-max) {
      .navbar-nav {
        .open {
          .dropdown-menu {
            background-color: #FFF;
            position: absolute;
            border: 1px solid #CCC;
          }
        }
      }
  }

  /* Medium Devices, Desktops */
  @media (min-width: $screen-md) {
    padding: 0;
    margin-bottom: 0;
  }
}

/* Small Devices, Tablets */

@media (max-width: $screen-xs-max) {
    li.user-profile-dropdown {
        >a {
            padding: 2px;
            margin-top: 8px;
        }
        .user-id {
            span {
                max-width: 100px;
            }
        }
    }
}

/*ENV NAME*/

.env-name {
  text-align: center;
  font-weight: 500;
}

.env-name-label {
  background-color: #222;
  padding: 6px;
  color: #FFF;
  margin-right: 4px;
}

.env-name-nav-item {
  .env-name-label {
    border-radius: 5px;
    display: inline-block;
  }
}

/* Company Selector
   ========================================================================== */

.company-search {
    padding-top: 20px;
}


/* Move Company
   ========================================================================== */

.move-company {
    label {
        font-weight: normal;
    }
    a {
        line-height: 34px;
    }
    h3 {
        font-size: 16px;
    }
    .to-company {
        margin-bottom: 20px;
    }
}


/* Company Settings
   ========================================================================== */

.company-settings .action-link {
    margin-left: 20px;
}

.double-margin {
    margin-bottom: 40px !important;
}

.sub-company-alert {
    width: 100%;
    line-height: 20px;
    bottom: -24px;
    background-color: lighten($brand-danger, 25%);
    color: #000;
    padding: 0 20px;
    border-bottom: 1px solid lighten($brand-danger, 15%);
    text-align: center;
    display: inline-block;
    height: 21px;
}

.sub-company-alert .sub-company-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 65%;
    float: left;
}

@media (min-width: $screen-xs-max) {
    .sub-company-alert .link-button {
        display: inline-block;
        font-weight: bold;
    }
}

@media (max-width: $screen-xs-max) {
    .sub-company-alert .link-button {
        float: right;
        font-weight: bold;
    }
}

.authorization-modal {
    text-align: center;
    .profile-img {
        width: 72px;
        height: 72px;
        -webkit-border-radius: 72px;
        -moz-border-radius: 72px;
        border-radius: 72px;
    }
    p,
    .btn {
        margin-top: 40px;
    }
}

.user-profile-dropdown {
    .dropdown-toggle {
        .user-id {
            span {
                display: block;
                line-height: 16px;
                font-size: 13px;
                text-align: right;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                &.pending-registration {
                    line-height: 32px;
                    display: none;
                }
                @media (max-width: $screen-sm) {
                    width: 120px;
                }
            }
            strong {
                font-size: 12px;
                color: #999;
            }
        }
    }
    .google-account {
        margin: 15px;
        line-height: 32px;
    }
}

.dropdown-header {
    color: #555;
    padding: 10px;
    &.sub-company-header {
        background: #f8e4e2;
    }
    p {
        line-height: 18px;
        margin: 0;
        font-size: 13px;
        overflow: hidden;
        @media (max-width: $screen-xs) {
            strong {
                text-overflow: ellipsis;
                display: block;
                overflow: hidden;
                max-width: 260px;
            }
            a {
                display: block;
                margin-top: 5px;
            }
            .text-muted {
                display: none;
            }
        }
    }
}

.actions-nav {
    > li > a .label {
        position: absolute;
        left: 60%;
        top: -2px;
        z-index: 1;
        padding: 4px 7px;
        font-size: 50%;
        vertical-align: middle;
    }
    .dropdown .company-menu {
        right: -62px;
    }
    .dropdown .company-menu::before {
        right: 80px;
    }
    .dropdown .company-menu::after {
        right: 81px;
    }
}

.company-settings .instructions {
    font-size: small;
    font-style: italic;
}

/* AppCues Launchpad
   ========================================================================== */

.appcues-widget-dropdown {
	padding:0px !important;
}

.appcues-widget-icon {
    @extend .my-0;

    margin-right: 20px;
}

.appcues-widget-icon:after {
	background: #107DDA !important;
	border-radius:3px !important;
	bottom:26px !important;
	right:-15px !important;
}

.appcues-widget-icon[data-appcues-count="0"]::after {
    display: none !important;
}

.appcues-widget-dropdown {
	border-radius:8px;
	box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
	width:320px;
}

.appcues-widget-dropdown .appcues-widget-header {
    padding-left: 10px !important;
    line-height: 48px;
    height: 60px;
	margin-left: 4px;
    color: #107DDA;
}

.appcues-widget-list li a {
	color:black;
    padding: 0;
    margin: 0!important;
    display: flex !important;
    align-items: center !important;
    padding-right: 10px;
    column-gap: 8px;
    text-indent: -23px;
    padding-left: 25px !important;
}

.appcues-widget-list li time {
    align-self: center !important;
}

.appcues-widget-list li {
    padding: 1em 50px 1em 1em !important;
    display: flex;
    align-items: center;
    position: relative !important;
}

.appcues-widget-list li:hover {
    background: #f8f8f8;
}

.appcues-widget-list li time {
	top:0px !important;
	padding:20px 10px;
}

.appcues-nothing-new {
	font-size: 12px!important;
	padding: 8px!important;
	margin-left: 6px;
}


