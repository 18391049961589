/* ==========================================================================
   Products
   ========================================================================== */


/* Search Products
   ========================================================================== */

.form-product-search {
  margin-bottom: 0;
}


/* ==========================================================================
   Product Details, Order
   ========================================================================== */

.product-info,
.order-info,
.subscription-info {
  /* Display & Box Model */
  padding: 20px;
  border: solid 1px #ddd;
  .info-row {
    margin-top: 20px;
  }
  /* Visual */
  background: #fff;
  border-radius: 5px;
  min-height: 400px;
  position: relative;
  h3.experimental,
  h3.inactive {
    color: red;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
  .qty-message {
    font-style: italic;
  }
  .text-red {
    color: red;
  }
}

.product-header {
  margin-bottom: 10px;
}

.product-vendor {
  font-weight: bold;
}

.product-trial {
  color: #45b864;
}

.product-expired {
  color: red;
}

.price-disabled {
  color: #ccc;
}


/* Quantity */

.product-qty li {
  display: inline-block;
  margin-right: 8px;
  line-height: 28px;
  vertical-align: top;
}


/* Cart buttons */

.cart-buttons {
  margin-top: 20px;
}

.cart-buttons .btn {
  margin: 5px 0;
  min-width: 160px;
}

.order-cancel-success {
  font-weight: bold;
}

.hr-dashed {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 2px dashed #cbcbcb;
}


/* Media queries
   ========================================================================== */

@media (min-width: $screen-sm) {
  .product-info {
    padding: 40px;
  }
  .cart-buttons li {
    /* Positioning */
    float: left;
    /* Display & Box Model */
    margin-left: 3%;
  }
  .cart-buttons li:first-child {
    margin-left: 0;
  }
}
