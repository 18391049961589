.colors-container {
  .colors-checkbox {
    input[type=checkbox] {display:none;}

    input[type=checkbox] + label:before {
      font-family: FontAwesome;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      content: '\f096';
      cursor: pointer;
      font-size: 20px;
      color: #999999;
      margin-right: 10px;
    }

    input[type=checkbox]:checked + label:before {
      color: #107dda;
      content: '\f14a';
    }
  }
}
