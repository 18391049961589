.nav-tabs-justified {
  >li>a {
    background: none;
    font-size: 18px;
    &:hover,
    &:focus {
      border: 1px solid transparent;
      background: none;
    }
    img {
      height: 25px;
      margin-right: 10px;
    }
  }
}
@media (min-width: $screen-sm) {
  .nav-tabs-justified {
    >li>a {
      border: 1px solid transparent;
      border-bottom: 1px solid #ddd;
      padding: 15px 5px 15px 6px;
      &:hover,
      &:focus {
        border: 1px solid transparent;
        border-bottom: 1px solid #ddd;
        background: none;
      }
    }
    >li.active>a {
      background-color: $white;
      border-bottom-color: $white;
      &:hover,
      &:focus {
        border-bottom: 1px solid transparent;
      } 
    }
  }

  .nav-tabs-panel {
    border-top:0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.nav-tabs-justified.nav-tabs-no-border {
  >li>a {
    font-size: 14px;
    padding: 5px;
    border: 0;
    border-bottom: 5px solid #e6e6e6;
    &:hover,
    &:focus {
      border-bottom: 5px solid #BBB;
    }
  }
  >li.active>a {
    background-color: #fcfcfc;
    border: 0;
    border-bottom: 5px solid #1ebb53;
  }
}
