.alert
{
    &.alert-premium
    {
        @include alert-variant($alert-premium-bg, $alert-premium-border, $alert-premium-text);
    }
    &.alert-plan
    {
        font-size: 13px;
        margin: 0 !important;
        padding: 6px;
        color: #020621;
        border-radius: 0;

        &.plan-active
        {
            border-color: #d6e9c6;
            background-color: #dff0d8;
        }
        &.plan-suspended
        {
            background-color: #fcf8e3;
            border-color: #faebcc;
        }
    }
    &.alert-lighter-gray
    {
        @include alert-variant($lighter-gray, $lighter-gray, $text-color);
    }
}

.madero-style {
    &.alert {
        background-color: white;
        padding: 20px;

        streamline-icon {
            margin-right: 1rem;
            height: 16px;
        }

        mat-icon {
            padding-top: 0.3rem;
        }

        &.alert-danger {
            background-color: $alert-danger-bg;
            border-color: $danger;

            strong {
                color: $danger;
            }

            mat-icon:before {
                color: $danger;
            }
        }

        &.alert-warning {
            border-color: $state-warning-text;
            background-color: $alert-warning-bg;

            strong {
                color: $state-warning-text;
            }
        }

        &.alert-success {
            border-color: $rise-green;

            strong {
                color: $rise-green;
            }
        }

        &.alert-info {
            border-color: $rise-blue;
            background-color: $state-info-bg;

            strong {
                color: $rise-blue;
            }

            svg {
                fill: $rise-blue;
            }
        }

    }
}
