.color-wheel, .color-wheel:hover {
  background-image: url("//s3.amazonaws.com/Rise-Images/UI/color_wheel.png");
  background-repeat: no-repeat;
  background-position: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0 4px 4px 0 !important;
  border-left: none;
}
.image-placeholder {
  width: 123px;
  height: 123px;
  background: url('//s3.amazonaws.com/Rise-Images/UI/transp_bg.png') repeat;
  margin-right: 10px;
  border-radius: 4px;
  text-align: center;
  padding-top:45px;
  font-size: 32px;
  border: 1px solid #CCC;
  line-height: 0px;
}
