.panel {
 border-color: $light-border-color;
}

.progress-panel {
    overflow: hidden;
    .panel-body {
        border-left: 3px solid #d4d4d4;
         .table { // see if this is required?
          margin-bottom: 0;
            tbody > tr {
                > td {
                    padding: 12px 8px;
                    line-height: 1.22857;
                    vertical-align: top;
                }
                &:first-child > td {
                    border-top: 0;
                }
            }
        }
    }
    .panel-header {  //TODO: Should this be global?
        background: #f7f7f7;
        border-left: 3px solid #d4d4d4;
        padding: 10px 15px;
        color: $gray;
        cursor: pointer;
        overflow: hidden;
        p {
            float: left;
        }
        @media (max-width: $screen-sm) {
            p {
                float: none;
            }
            .actions-block {
                text-align: left;
            }
        }
    }
    p {
        overflow: hidden;
        margin: 5px 0 5px 0;
        a {
            text-transform: capitalize;
            float: right;
        }
    }
    .actions-block {
        text-align: right;
    }
    .collapse-control {
        line-height: 28px;
        padding: 0 15px;
        margin-right: -15px;
        float: right;
    }
}
