.grid-selector {
  max-height: calc(100vh - 340px);
}

.grid-selector-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  padding: 4px;
}

.grid-selector-list-item {
  width: 32.5%;
  height: 120px;
  margin-bottom: 5px;
  color: #555;
  align-self: center;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  position: relative;
  fill: $rise-blue;

  div {
    height: 100%;
    display: flex;
    flex-direction: column;

    .image-container {
      position: relative;
      flex: 1 1 100%;
      overflow: hidden;
      align-items: center;
      justify-content: center;
    
      &::before, 
      &::after {
        content: '';
        display: none;
        width: 100%;
        height: 100%;
        position:absolute;
      }
      // Selected file overlay
      &::before {
        opacity: 0.7;
        background-color: $rise-blue;
      }
      &::after {
        color: $text-inverse;
        content: '\f00c';
        font-family: FontAwesome;
        font-size: 3em;
        line-height: 2.25;
        top: 0;
      }
    }  

    &.list-item--selected {
      .image-container {
        &::before, 
        &::after {
          display: block;
          overflow: hidden;
        }
      }
      .list-item-label {
        background: $rise-blue;
        color: $text-inverse;
      }
    }
  }

  .list-item-icon {
    width: 33%;
    margin-top: -20px;
  }

  .list-type_image {
    background-color: $gray-lightest;
    
    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }

  .list-item--throttled {
    .image-container {
      &::before {
        display: block;
        background: transparentize($danger, .5);
      }
      &::after {
        content: '\f05e';
        display: block;
        top: 0;
      }
    }

    .list-item-label {
      background: transparentize($danger, .65);
      color: $text-inverse;
    }
  }

  .list-item--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .list-item-label {
    font-weight: 500;
    line-height: 20px;
    padding: 2px 5px;
    overflow: hidden;
    font-size: calc(10px + 0.15vw);
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    flex: 0 0 2em;
  }

  .list-item_folder {
    border: 1px solid $rise-blue;

    .list-item-label {
      background: none;
    }
  }
}

.list-item-placeholder {
  visibility: hidden;
  height:         0 !important;
  margin-top:     0 !important;
  margin-bottom:  0 !important;
  padding-top:    0 !important;
  padding-bottom: 0 !important;
  border:         0 !important;
}


/* ==========================================================================
   Small devices (tablets, 768px and up)
   ========================================================================== */


@media (min-width: 768px) {
  .grid-selector {
    .grid-selector-list-item {
      width: 16.3%;
    }
  }
  .grid-selector-list_storage {

  }
}

/* ==========================================================================
   Medium devices (desktops, 992px and up)
   ========================================================================== */

@media (min-width: 992px) {
  .grid-selector {
    .grid-selector-list-item {
      width: 12.2%;
    }
  }
}
