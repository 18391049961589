.progress-indicator {
  background-color: red;
  width: 100%;
  text-align: center;
  padding: 0;
  display: inline-block;
  margin: 30px 0 0 0;
  cursor: default;
  .indicator {
    width: 15px;
    height: 15px;
    border: 4px solid #dddddd;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    .tag {
      position: absolute;
      top: -40px;
      left: 50%;
      transform: translateX(-50%);
      color: #dddddd;
      cursor: default;
    }
    &.complete {
      border-color: #4ab767;
      .tag {
        color: #4ab767;
        &:before {
          content: "\f00c";
          margin-right: 4px;
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
          color: #4ab767;
        }
      }
    }
    &.active {
      border-color: #4ab767;
      .tag {
        color: #4ab767;
        &:before {
          content: "";
          margin-right: 4px;
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
          color: #4ab767;
        }
      }
    }
  }
  .progress-bar-container {
    width: 17%;
    height: 2px;
    display: inline-block;
    background-color: #dddddd;
    position: relative;
    top: -6px;
    &:last-of-type {
      display: none;
    }
    .progress-bar {
      width: 0;
      height: 100%;
    }
    &.complete {
      background-color: #4ab767;
    }
  }
}