.label-primary {
  @include label-variant($label-primary-bg, $label-primary-color-hover);
}

.label-success {
  @include label-variant($label-success-bg, $label-success-color-hover);
}

.label-default {
  @include label-variant($label-default-bg, $label-link-hover-color);
  border:1px solid $light-gray;
  color: $gray;
}