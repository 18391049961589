// Help dropdown
#helpDropdownButton {
	&.add-notice {
		@extend .animated;
	    -webkit-animation-iteration-count: 3;
        animation-iteration-count: 3;
        -webkit-animation-name: pulse;
        animation-name: pulse;
        -webkit-animation-delay: 1s;
        animation-delay: 1s;
    }
}

.rise-logo {
  img {
    width: 150px;
  }
}

// Login page
.app-login {
	height: 100vh;
  width: 100vw;

  h1 {
    @extend .mb-5;
  }

	h4 {
		@extend .u_remove-top;
    margin-bottom: 0.5rem;
    font-size: 2.25rem;
	}

  p {
    margin-bottom: 2rem;
  }

  .has-error .control-label {
    color: $state-danger-text;
  }

	.rise-logo {
    margin-bottom: 80px;
	}
  .auth-panel {
    max-width: 480px;
    min-height: 100%;
    padding: 4rem 1rem;
    background-color: white;
    margin: 0 auto;

    @media (min-width: $screen-md) {
      width: 480px;
      margin: 0;
      padding: 4rem;
    }

    @media (min-width: $screen-lg) {
      width: 624px;
      max-width: 624px;
      padding: 4rem 10rem;
    }
  }

  .scroll-panel {
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    height: 100vh;
    width: 100vw;
  }
  .tv-room {
    display: none;
    height: 100%;
    margin-left: 480px;

    @media (min-width: $screen-lg) {
      margin-left: 624px;
    }

    .tv-panel {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      width: 100%;
      background-size: cover;
      background-attachment: fixed;
      background-position: calc(50% + 240px) center;

      @media (min-width: $screen-lg) {
        background-position: calc(50% + 312px) center;
      }

      .tv-position {
        height: max(52vh, 42vw);
        max-height: min(75vh, 50vw);
        padding: min(40px, 2vh);

        .tv-frame {
          margin: 0 auto;
          width: 35vw;
          max-width: min(600px, 100%);
          padding: 0.5vw 0.5vw 1vw;
          background-image: url('/images/sign-up/tv.png');
          background-size: contain;
          background-repeat: no-repeat;
          img {
            max-width: 100%;
            opacity: 1;
            transition: opacity 0.5s;

            &.fade {
              opacity: 0;
            }
          }
        }
      }
    }

    @media (min-width: $screen-md) {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}

// Weekly templates
.weekly-templates {
	.collapsed-label {
		font-size: 18px;
	}
	.expanded-panel {
		color: #444;
	}
	.expanded-message {
		font-size: 16px;
	}
	.col-centered {
		float: none; display:
		inline-block;
		@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
			width: 49%;
		}
		@media (min-width: $screen-md) and (max-width: $screen-md-max) {
			width: 33%;
		}
		@media (min-width: $screen-lg) {
			width: 24%;
		}
	}
}
