// Billing Page container
.billing-app {

  a.btn-pay-now {
    line-height: 18px;
    min-height: auto;
    padding-top: 7px;
    padding-bottom: 7px;
    width: 105px;
  }

  .border-container {
    & > .panel-body {
      @extend .u_padding-20;
      height: 100%;
    }
    & > .panel-footer {
      @extend .u_padding-20;
      padding-top: 0;
      border-top: none;
    }

    &.scrollable-list {
      .table>tbody>tr>td, .table>thead>tr>th {
        @extend .u_padding-20;
      }
    }
  }

  .status {
    &.paid svg {
      fill: $rise-green;
    }
    &.unpaid svg {
      @extend .ml-2;

      fill: $danger;
    }
  }

  .row.subscription-card-row {
    display: flex;
    flex-wrap: wrap;

    & > [class*='col-'] {
      display: flex;
      flex-direction: column;

      .subscription-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: center;
        @extend .mb-5;

        h1 {
          font-size: 48px;
          margin-top: 10px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .subscription-footer {
    text-align: center;

    label {
       svg {
        fill: $mid-gray;
        vertical-align: middle;
        margin-left: 5px;
       }
    }
  }

}
