@use "@angular/material" as mat;
/* Variables
   ========================================================================== */

// Colors
$rise-green: #36a860;
$danger: #d9534f;
$google-red: #df4a32;
$rise-premium: #dbf0e0;
$rise-blue: #216ad9;

// Tones
$white: #fff;
$lighter-gray: #f2f2f2;
$light-gray: #e6e6e6;
$mid-gray: #999999;
$gray: #555;
$dark-gray: #4d4d4d;

$border-color: #CCC; //TODO: change to #C9D2DB
$light-border-color: #DDDDDD;
$border: 1px solid $border-color;

// Spacing
$title-margin: 6px;

// New Style
$madero-black: #0d1216;
$madero-green-active: #2d9653;

// Text
$default-text: $madero-black;
$text-inverse: $white;

// Padding
$pad-sm: .25rem; //4px
$pad-md: .5rem; //8px
$pad-lg: 1rem; //16px

// Shadows
$bottom-shadow: 0 .25rem .25rem $mid-gray;
$bottom-shadow-small: 0 0 2px $mid-gray;
$vignette-shadow: inset 0 0 3px $mid-gray;

// Template Cards
$card-border-radius: 1rem;
$card-image-radius: .5rem;
$card-color: $white;
$card-margin: $pad-md;

//Transitions
$transition-default: .25s ease all;

// Material UI
$mat-input-size: 40px;
$mat-button-size: 40px;
$mat-icon-button-padding: 8px;
$mat-small-button-size: 24px;
$mat-small-icon-size: 16px;

// Generated using https://supercolorpalette.com/
// https://supercolorpalette.com/?scp=G0-lch-8BB4FF-9DC1FF-AFD0FF-C0DEFF-D2ECFF-E4FAFF (light colors)
// https://supercolorpalette.com/?scp=G0-lch-276BD9-4E82EE-6D9AFF-8AB3FF (mid colors)
// https://supercolorpalette.com/?scp=G0-lch-276BD9-2364CC-205DBE-1C56B1 (dark colors)
// 600 is $rise-blue
$rise-blue-palette: (
  50: #E4FAFF,
  100: #D2ECFF,
  200: #AFD0FF,
  300: #8AB3FF,
  400: #6D9AFF,
  500: #4E82EE,
  600: #216BD9,
  700: #2364CC,
  800: #205DBE,
  900: #1C56B1,
  contrast: (
    50: #0d1216,
    100: #0d1216,
    200: #0d1216,
    300: #0d1216,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff
  )
);

// Generated using https://supercolorpalette.com/
// https://supercolorpalette.com/?scp=G0-lch-33A75F-55B373-70BE86-8ACA9A-A2D5AE-BAE1C3-D2ECD7-EAF7ED (light colors)
// https://supercolorpalette.com/?scp=G0-lch-33A75F-2F9655-2B844C-267342-226339-1D5330 (dark colors)
// 400 is $rise-green
$rise-green-palette: (
  50: #EAF7ED,
  100: #D2ECD7,
  200: #A2D5AE,
  300: #70BE86,
  400: #36A860,
  500: #2F9655,
  600: #2B844C,
  700: #267342,
  800: #226339,
  900: #1D5330,
  contrast: (
    50: #0d1216,
    100: #0d1216,
    200: #0d1216,
    300: #0d1216,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff
  )
);

$rise-primary: mat.m2-define-palette($rise-green-palette, 400);
$rise-accent: mat.m2-define-palette($rise-blue-palette, 600);
