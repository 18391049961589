#addStoreProductModal {
  #template-filters, #template-grid {
    position: relative;
    padding-right: 7px;
    padding-left: 8px;
    text-align: left;
  }
  #template-filters {
    max-height: calc(100vh - 175px);
    overflow-y: auto;
    padding-bottom: 1px;
  }
}

mat-month-view .mat-calendar-body-cell.has-events {
  .mat-calendar-body-cell-content {
    border: 1px solid $rise-blue;
    color: $rise-blue;
  }
}

.mat-calendar-body-cell, mat-month-view .mat-calendar-body-cell {
  .mat-calendar-body-cell-content {
    &.mat-calendar-body-selected {
      background-color: $rise-blue;
      color: $white;
    }
  }

  &:not(.mat-calendar-body-disabled):hover {
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
      background-color: #199cfb4c !important;
    }
  }
}

.product-grid > .panel {
  max-height: calc(100vh - 175px);
}

.product-grid.widget-grid >.panel {
  max-height: calc(100vh - 275px);
}

/* ==========================================================================
   Small devices (tablets, 768px and up)
   ========================================================================== */


@media (min-width: 768px) {
  #addStoreProductModal {
    #template-filters {
      overflow-y: auto;
      max-height: calc(100vh - 125px);
      width: 275px;
      float: left;
    }
    #template-grid {
      width: calc(100% - 275px);
      float: left;
    }
  }
  #weekly-templates-expanded {
    #template-calendar {
      margin-right: 15px;
      max-width: 240px;
    }
  }
}

// New styles
$list-pad: $pad-lg;
$card-pad: $pad-lg;
$title-pad: $pad-md;
$header-pad: calc(#{$card-pad} + #{$title-pad});

#weekly-templates-expanded {
  #template-calendar, #template-grid {
    position: relative;
    padding-right: 7px;
    padding-left: 8px;
    float: left;
  }
  #template-calendar {
    width: 25%;

    @include breakpoint(sm) {
      display: none;
    }
    @include breakpoint(xl) {
      width: 240px;
    }
  }
  .template-card {
    flex: 1 1 33.333%;
    max-width: calc(33.333% - #{$list-pad} * 2);
    margin: $list-pad;

    @include breakpoint(sm) {
      flex: 1 1 100%;
      max-width: calc(100% - #{$list-pad} * 4);
    }
  }
}

// Featured
.featured-templates {
  background: $gray-lightest;
  text-align: center;

  .container {
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }

  .template-list {
    justify-content: center;
  }

  // Only break on even numbers
  .template-card {
    flex: 1 1 25%;
    max-width: calc(25% - #{$list-pad} * 2);
    margin: $list-pad;

    @include breakpoint(md) {
      flex: 1 1 50%;
      max-width: calc(50% - #{$list-pad} * 2);
    }
  }
}

.featured-title {
  @extend h3;
}

.collapse-featured {
  @include icon-toggle-expanded;
  position: absolute;
  right: 1.5rem;
  top: 2rem;
  span {
    @include hidden;
  }
}

.expand-featured {
  @include icon-toggle-collapsed;
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  span {
    @include hidden;
  }
}

// Template List
.professional-content {
  padding: $pad-lg;
  overflow-y: auto;
}

.product-grid-header {
  margin: $card-pad $header-pad;
  padding-top: $card-pad;
  font-size: 1.6rem;
  font-weight: bold;
}
.product-grid-header:first-of-type {
  margin-top: $pad-sm;
}

.product-grid-group-header {
  margin: $card-pad $header-pad 0;
  padding-top: $card-pad;
  font-size: 1.8rem;
  font-weight: normal;
}

.product-grid-group-header {
  margin: $card-pad $header-pad 0;
  padding-top: $card-pad;
  font-size: 1.8rem;
  font-weight: normal;
}

.template-list {
  border: none;
  margin: $list-pad 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

// Card
.template-card {
  background: $card-color;
  fill: $white;
  border: 1px solid transparent;
  border-radius: $card-border-radius;
  flex: 1 1 33.33%;
  max-width: 33.33%;
  padding: $card-pad;

  .btn {
    @include hidden;
  }

  &:hover,
  &:focus-within {
    border-color: $light-border-color;
    box-shadow: $bottom-shadow;
    z-index: 10;

    .btn {
      @include visible;
      width: 40px;
    }
  }

  @include breakpoint(xl) {
    flex: 1 1 25%;
    max-width: 25%;
  }

  @include breakpoint(md) {
    flex: 1 1 50%;
    max-width: 50%;
  }
}

// Image
.template-image {
  @extend .u_clickable;

  border-radius: $card-image-radius;
  width: 100%;
  padding-bottom: 56.25%; // 16:9 ratio
  position: relative;
  overflow: hidden;

  img, svg {
    background: transparentize($rise-blue, .75);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    min-height: 100%;
  }

  // CTA Behavior
  &:hover {
    .cta {
      top: 0;
    }
  }

  .template-tag {
    position: absolute;
    right: 0;
    bottom: 0;
    font-weight: bold;
    background-color: $white;
    padding: $pad-sm $pad-lg;
    border-top: 1px solid $light-border-color;
    border-left: 1px solid $light-border-color;
    border-bottom: 1px solid $white;
    border-right: 1px solid $white;
    border-top-left-radius: $card-image-radius;
  }
}

// CTA
.cta {
  background: transparentize($white, .1);
  position: absolute;
  top: -4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $inter;
  font-size: 1.2rem;
  z-index: 21;
  border-radius: 0 0 $card-border-radius $card-border-radius;
  transition: $transition-default;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  box-shadow: $bottom-shadow-small;
  padding: $card-pad;
}

// Vignette
.template-fx {
  border-radius: $card-image-radius;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  box-shadow: $vignette-shadow;
  z-index: 21;
}

// Details
.template-details {
  display: flex;
  justify-content: flex-start;
  padding: $card-pad $title-pad 0;

  .template-title {
    font-family: $inter;
    font-size: 1.6rem;
    margin: 0 $title-pad 0 0;
    text-align: left;
    flex: 1 1 100%;
  }

  .template-button {
    position:relative;
    flex: 0 0 4rem;
    height: 4rem;
  }

  .alert-button {
    .btn {
      @include icon-gear;
    }
  }

  .btn {
    flex: 0 0 4rem;
    height: 4rem;
    @include icon-plus;

    span {
      @include hidden;
    }
  }
}