.file-picker {
  color: $text-muted;
  cursor: pointer;
  &:hover {
    .file-picker-icon {
      background-color: #FFF;
    }  
  }

  input { @extend .sr-only; }

  .file-picker-icon {
    width: 32px;
    height: 32px;
    background-color: #ddd;
    border-radius: 16px;
    line-height: 32px;
    text-align: center;
    margin-left: 5px;
  }
  
  :disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: .65;
  }
}
