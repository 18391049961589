.display-fields-body {
  .display-screenshot-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .display-screenshot {
      max-width: 100%;
      aspect-ratio: 16 / 9;
      background: $light-gray;

      .display-screenshot-info {
        @extend .text-center;
        @extend %vertical-align;
      }
    }
  }

  .img-screenshot {
    max-width: 100%;
    object-fit: contain;
  }

  .display-screenshot-panel, .img-screenshot {
    width: 100%;

    @media (min-width: $screen-md) {
      width: auto;
    }
  }

}

/* Deprecate Below
 +   ==========================================================================
 +   ==========================================================================
 +   ==========================================================================
 +   ==========================================================================
 +   ========================================================================== */

.display-name {
  span {
    @extend .u_ellipsis-md;
    max-width: 240px;
    display: inline-block;
    vertical-align: middle;
  }
}

.display-address {
  span {
    @extend .u_ellipsis-md;
    max-width: 320px;
    display: inline-block;
    vertical-align: middle;
  }
}

.offline {
  &:before {
    color: #e74c3c;
  }
}

.notinstalled {
  &:before {
    color: #444;
  }
}

.online {
  &:before {
    color: $rise-green;
  }
}


