.playlist-item-selector {
  ul {
    gap: 5px;

    li {
      width: calc(33.33% - 3.333px);

      a {
        display: inline-block;
        color: $madero-black;
        width: 100%;
        border: 1px solid $mid-gray;
        border-radius: 5px;

        &:hover {
          border-color: $rise-blue;
          background-color: lighten($rise-blue, 50%);
        }

        &.extra-padding {
          padding-bottom: 11px !important;
        }
      }

      &.disabled {
        background-color: $lighter-gray;

        a {
          color: $mid-gray; // TODO: change to $gray

          &:hover {
            border-color: $mid-gray;
            background-color: $lighter-gray;
          }

          svg {
            fill: $mid-gray;
          }
        }
      }
    }
  }
}